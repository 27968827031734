<template>

  <v-container fluid class="pt-0">

  <div class="text-center">

    <v-dialog
      v-if="error"
      v-model="errorDialog"
      persistent
      min-width="300"
      max-width="400">

      <v-card>
        <v-card-title class="transfer-failed mb-6">
          Funds Transfer Failed
        </v-card-title>
        <v-card-subtitle>
          &nbsp;
        </v-card-subtitle>

        <template v-if="error.code">
          <v-card-text>
            <div class="mb-6">
              Code: {{ error.code }}
              <br />
              Name: {{ error.name }}
              <br />
              Message: {{ error.message }}
            </div>

            <div class="overline">{{ transactionType }} Amount</div>
            <div class="text-h3" style="text-transform: capitalize;">
              {{ formatCurrency(transactionAmount, terminalLimits.currency) }}
            </div>

            <template v-if="error.underlyingError">
              <br />
              Underlying Error: {{ error.underlyingError }}
            </template>
          </v-card-text>
        </template>

        <template v-else>
          <v-card-text>
            {{ error }}
          </v-card-text>
        </template>

        <v-card-actions>

          <v-spacer />

          <v-btn @click="errorDialog = false">
            Close
          </v-btn>

          <v-btn @click="beginTransferFunds()">
            Retry
          </v-btn>
        </v-card-actions>

      </v-card>

    </v-dialog>

    <v-dialog
      v-model="transferDialog"
      persistent
      min-width="300"
      max-width="400">

      <v-card color="primary" dark>

        <v-card-title>
          Transferring Funds
        </v-card-title>

        <v-card-text class="mt-6">
          <v-progress-linear
            indeterminate
            color="white"
            height="16"
            rounded
            striped
            class="mt-0 mb-6" />
          <div class="overline">{{ transactionType }} Amount</div>
          <div class="text-h3" style="text-transform: capitalize;">
            {{ formatCurrency(transactionAmount, terminalLimits.currency) }}
          </div>
        </v-card-text>
      </v-card>

    </v-dialog>

    <v-dialog
      v-model="successDialog"
      persistent
      min-width="300"
      max-width="400">

      <v-card>

        <v-card-title>
          Funds Transferred Successfully
        </v-card-title>

        <v-card-text class="mt-6">
          <div class="overline">{{ transactionType }} Amount</div>
          <div class="text-h3" style="text-transform: capitalize;">
            {{ formatCurrency(transactionAmount, terminalLimits.currency) }}
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn @click="resetTransaction(); successDialog = false" color="primary">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
  </div>

  <v-row>
    <v-col class="pa-0 pa-sm-3">

      <v-stepper
        v-model="e6"
        vertical
        style="border: 0px;"
      >
        <v-btn v-if="e6 > 0" @click="resetTransaction(); e6 = 1" fab x-small class="ma-4" style="position: absolute; right: 0px; z-index: 2;">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>

        <v-stepper-step
          class="pb-0 pa-sm-6"
          :complete="e6 > 1"
          style="z-index: 1;"
          step="1"
        >
        <span style="text-transform: capitalize;">Transaction Type <b>{{ transactionType ? ` - ${transactionType}` : '' }}</b></span>
        </v-stepper-step>

        <v-stepper-content step="1">
          <TransactionTypeStep v-on:transaction-type-set="e6 = 2" :isDisplayed="e6 == 1" />
        </v-stepper-content>

        <v-stepper-step
          class="pb-0 pa-sm-6"
          :complete="e6 > 2"
          step="2"
        >
          <span style="text-transform: capitalize;">
            {{ transactionType }} Amount
            <template v-if="transactionAmount">
              <b>{{ new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(transactionAmount) }}</b>
            </template>
          </span>

          <small v-if="transactionType">
              Min {{formatCurrency(terminalLimits.limits[transactionType].min, terminalLimits.currency)}},
              Max {{formatCurrency(terminalLimits.limits[transactionType].max, terminalLimits.currency)}},
              Fee {{formatCurrency(terminalLimits.fees[transactionType], terminalLimits.currency)}}
          </small>
        </v-stepper-step>

        <v-stepper-content step="2">
          <TransactionAmountStep v-on:transaction-amount-set="e6 = 3" :isDisplayed="e6 == 2" />
        </v-stepper-content>

        <v-stepper-step
          class="pb-0 pa-sm-6"
          :complete="e6 > 3"
          step="3"
        >
          Enter Phone Number

          <small v-if="e6 == 3" style="max-width: 500px;">
            Mobile Phone Number associated with your Access Cash Spot™ account.
          </small>
        </v-stepper-step>

        <v-stepper-content step="3">
          <EnterPhoneStep v-on:phone-set="e6 = 4" :isDisplayed="e6 == 3" />
        </v-stepper-content>

        <v-stepper-step
          class="pb-0 pa-sm-6"
          :complete="e6 > 4"
          step="4">
          Enter Verification Code

          <small v-if="e6 == 4" style="max-width: 500px;">
            Verification Code sent to {{ phone | VMask('(###) ###-####') }}
          </small>
        </v-stepper-step>

        <v-stepper-content step="4">
          <VerifyPhoneStep v-on:verification-code-set="beginTransferFunds()" :isDisplayed="e6 == 4" />
        </v-stepper-content>

        <!--<v-stepper-step-->
          <!--class="pb-0 pa-sm-6"-->
          <!--step="">-->

          <!--<div v-if="e6 == 5">-->
            <!--Transferring Funds - {{ new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(transactionAmount) }}-->
          <!--</div>-->

          <!--<small v-if="e6 == 5" style="max-width: 500px;">-->
            <!--Transferring {{ new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(transactionAmount) }}-->
          <!--</small>-->
        <!--</v-stepper-step>-->

        <!--<v-stepper-content step="5">-->
          <!--<ShowTransferProgress v-on:transfer-complete="true" :isDisplayed="e6 == 5" />-->
        <!--</v-stepper-content>-->

      </v-stepper>

    </v-col>
  </v-row>

  </v-container>

</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import EnterPhoneStep from '@/components/EnterPhoneStep'
import TransactionTypeStep from '@/components/TransactionTypeStep'
import TransactionAmountStep from '@/components/TransactionAmountStep'
import VerifyPhoneStep from '@/components/VerifyPhoneStep'
//import TransferFundsProgressDialog from '@/components/TransferFundsProgressDialog'
//import ShowTransferProgress from '@/components/ShowTransferProgress'

export default {

  data() {
    return {
      e6: 1,
      transferDialog: false,
      successDialog: false,
      errorDialog: false,
      error: undefined,

      //errorDialog: true,
      //error: {name: 'PROCESSOR_ERROR', message: 'Transaction not supported for card', code: 1236},
    }
  },

  computed: {
    ...mapState('Transaction', ['transactionType', 'transactionAmount', 'phone', 'verificationCode']),
    ...mapGetters('Channel', ['terminalLimits']),
  },

  methods: {
    ...mapActions('Transaction', ['resetTransaction', 'transferFunds']),

    updateStep() {
      this.e6 = this.verificationCode && this.phone
        ? 4
        : this.transactionAmount
        ? 3
        : this.transactionType
        ? 2
        : 1
    },

    async beginTransferFunds() {
      debugger
      this.transferDialog = true
      this.errorDialog = false
      this.successDialog = false
      this.error = undefined

      try {
        await this.transferFunds()
        this.successDialog = true
        this.transferDialog = false
        this.errorDialog = false
        this.error = undefined
      }
      catch (error) {
        this.error = error.response?.data ?? 'Unknown Error'
        this.successDialog = false
        this.transferDialog = false
        this.errorDialog = true

        //this.successDialog = true
        //this.transferDialog = false
        //this.errorDialog = false
        //this.error = error
      }
    }
  },

  components: {
    EnterPhoneStep,
    TransactionTypeStep,
    TransactionAmountStep,
    VerifyPhoneStep,
    //TransferFundsProgressDialog,
  },

  watch: {

    transactionType: {
      handler() {
        this.updateStep()
      },
      immediate: true,
    },
  },
}

</script>

<style scoped lang="scss">
::v-deep {
  .v-stepper--vertical .v-stepper__content, .v-stepper__step v-stepper__step--inactive {
    margin-left: 0px;
    border-left: 0px;
  }
  .v-card__title {
    background-color: var(--v-primary-base);
    color: var(--v-headerText-base);
  }
  .v-card__title.transfer-failed {
    background-color: var(--v-error-base);
    color: var(--v-headerText-base);
  }
  .v-input--is-label-active label {
    font-weight: bold !important;
    color: var(--v-primary-base);
  }
  .v-card__title.headline {
    .v-input--switch label {
      color: white;
    }
  }
}
</style>
