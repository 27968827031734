import { render, staticRenderFns } from "./VerifyPhoneStep.vue?vue&type=template&id=1ed01d11&scoped=true&"
import script from "./VerifyPhoneStep.vue?vue&type=script&lang=js&"
export * from "./VerifyPhoneStep.vue?vue&type=script&lang=js&"
import style0 from "./VerifyPhoneStep.vue?vue&type=style&index=0&id=1ed01d11&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ed01d11",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VBtn,VCard,VCol,VIcon,VRow})
