<template>

  <v-row>
    <v-col>

        <v-alert class="mx-8" v-if="error" type="error">
          {{ error }}
        </v-alert>

          <div
            class="amount-wrapper mb-4 text-h3"
            style="max-width: 500px; text-align: center;">

            <span v-if="numbers.length == 0" class="amount">
              <span class="symbol">$</span>
              0
            </span>
            <span v-else :class="{amount: true, 'orange--text': hasValidAmount ? false : true}">
              <span :class="{symbol: true, 'orange--text': hasValidAmount ? false : true}">
                $
              </span>
              {{ numbers.join('') }}
            </span>
          </div>

        <v-card
          flat
          class="number-buttons"
          style="display: grid; grid-template-columns: 1fr 1fr 1fr; border: 0; max-width: 500px;">

          <v-btn
            style="box-sizing: border-box"
            class="ma-3"
            height="100"
            v-for="(item, index) in Array(9)"
            :key="index"
            @click="digit(index+1)">
            {{ index+1 }}
          </v-btn>
          <v-btn
            height="100"
            class="ma-3"
            :disabled="numbersEmpty"
            @click="clear">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
          <v-btn
            height="100"
            class="ma-3"
            :disabled="amount == 0"
            @click="digit(0)">
            0
          </v-btn>
          <v-btn
            height="100"
            class="ma-3"
            :disabled="numbersEmpty"
            @click="backspace">
            <v-icon>mdi-backspace-outline</v-icon>
          </v-btn>

          <v-btn
            color="primary"
            justify="right"
            height="100"
            style="grid-column: 2;"
            class="ma-3"
            :disabled="!hasValidAmount"
            @click="submit">
            Next
          </v-btn>
        </v-card>

    </v-col>
  </v-row>

</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {

  props: ['isDisplayed'],

  data() {
    return {
      numbers: [],
      error: undefined,
    }
  },

  created() {
    console.log(this.numbers)
  },

  computed: {
    ...mapState('Transaction', ['transactionType', 'transactionAmount']),
    ...mapGetters('Channel', ['terminalLimits']),

    numbersEmpty() { return this.numbers.length == 0 },

    amount() {
      return parseInt(this.numbers.join('')) || 0
    },

    hasValidAmount() {
      if (!this.transactionType) return false

      const amount = parseInt(this.numbers.join(''))
      const limits = this.terminalLimits.limits[this.transactionType]
      return amount >= limits.min && amount <= limits.max
    },
  },

  methods: {
    ...mapActions('Transaction', ['setTransactionAmount']),

    submit() {

      this.setTransactionAmount(this.numbers.join(''))

      this.$emit('transaction-amount-set')
    },

    backspace() {
      this.numbers.pop()
    },

    clear() {
      this.numbers = []
    },

    digit(number) {
      this.numbers.push(number.toString())
    },

    keyListener(e) {
      //e.preventDefault()

      if (e.key == 'Backspace') {
        this.backspace()
      }
      else if (e.key == 'Escape') {
        this.clear()
      }
      else if (Number.isInteger(parseInt(e.key))) {
        this.digit(parseInt(e.key))
      }
      else if (e.key == 'Enter' && this.hasValidAmount) {
        this.submit()
      }
    },
  },

  watch: {
    isDisplayed: {
      handler(isDisplayed) {
        isDisplayed
        ? document.addEventListener('keydown', this.keyListener)
        : document.removeEventListener('keydown', this.keyListener)
      },
      immediate: true
    },

    transactionAmount: {
      handler(amount) {
        if (! amount) return this.numbers = []

        this.numbers = amount.split('')
      },
      immediate: true
    },
  },
}

</script>
<style scoped>
.symbol {
  color: silver;
}
.amount {
  text-align: left;
  position: relative;
  left: -8px;
}
>>> .number-buttons .v-btn {
  margin-left: 8px !important;
  margin-right: 8px !important;
}
</style>
