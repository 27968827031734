<template>

  <v-row style="max-width: 500px; height: 200px;">
    <v-col cols="6" class="fill-height mr-0 pr-0">
      <v-btn
        rounded
        class="rounded-tr-0 rounded-br-0"
        style="height: 100%; width: 100%;"
        v-on:click="setType('load')"
        >
        Load
      </v-btn>
    </v-col>
    <v-col cols="6" class="fill-height ml-0 pl-0">
      <v-btn
        rounded
        class="rounded-tl-0 rounded-bl-0"
        style="height: 100%; width: 100%;"
        v-on:click="setType('cashback')"
        >
        Cash Back
      </v-btn>
    </v-col>
  </v-row>

</template>
<script>
import { mapActions } from 'vuex'

export default {

  props: ['isDisplayed'],

  data() {
    return {
    }
  },

  methods: {
    ...mapActions('Transaction', ['setTransactionType']),

    setType(transactionType) {
      this.setTransactionType(transactionType)

      this.$emit('transaction-type-set')

      document.removeEventListener('keydown', this.keyListener.bind(this))
    },

    keyListener(e) {

      //e.preventDefault()

      if (e.key == 'c') {
        this.setType('cashback')
      }
      else if (e.key == 'l') {
        this.setType('load')
      }
    },
  },

  watch: {
    isDisplayed: {
      handler() {
        this.isDisplayed
        ? document.addEventListener('keydown', this.keyListener)
        : document.removeEventListener('keydown', this.keyListener)
      },
      immediate: true
    }
  },
}
</script>
