<template>

  <v-row>
    <v-col>

        <v-alert v-if="error" type="error" class="mx-8" style="max-width: 460px;">
          {{ error }}
        </v-alert>

          <div
            class="mb-4 ml-0 text-h5"
            style="max-width: 500px; text-align: center;">
            <span v-if="numbers.length == 0">
              <em style="color: silver;">(XXX) XXX-XXXX</em>
            </span>
            <span v-if="numbers.join('') == prefix">
              ({{ prefix }}) <em style="color: silver;">XXX-XXXX</em>
            </span>
            <span v-else>
              {{ numbers.join('') | VMask('(###) ###-####') }}
            </span>
          </div>

        <v-card
          flat
          class="number-buttons"
          style="display: grid; grid-template-columns: 1fr 1fr 1fr; border: 0; max-width: 500px;">
          <v-btn
            style="box-sizing: border-box"
            class="ma-3"
            height="100"
            v-for="(item, index) in Array(9)"
            :key="index"
            :disabled="numbersFull"
            @click="digit(index+1)">
            {{ index+1 }}
          </v-btn>
          <v-btn
            height="100"
            class="ma-3"
            :disabled="numbersEmpty"
            @click="clear">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
          <v-btn
            height="100"
            class="ma-3"
            :disabled="numbersFull"
            @click="digit(0)">
            0
          </v-btn>
          <v-btn
            height="100"
            class="ma-3"
            :disabled="numbersEmpty"
            @click="backspace">
            <v-icon>mdi-backspace-outline</v-icon>
          </v-btn>

          <v-btn
            color="primary"
            justify="right"
            height="100%"
            style="grid-column: 2; display: block;"
            class="ma-3"
            :disabled="!numbersFull"
            :loading="loading"
            @click="submit">

            <span v-if="$vuetify.breakpoint.xsOnly" class="pa-4" style="white-space: normal;">
              Verify with Text Message
            </span>
            <span v-else class="pa-4" style="white-space: normal;">
              Verify
              <br />
              <em style="font-size: 0.8em;">Send Text Message</em>
            </span>
          </v-btn>
        </v-card>

    </v-col>
  </v-row>

</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {

  props: {

    isDisplayed: {
      type: Boolean,
      default: false,
    },

    prefix: {
      type: String,
      default: '649',
    },

    count: {
      type: Number,
      default: 10
    },
  },

  data() {
    return {
      numbers: this.prefix.split(''),
      error: undefined,
      loading: false,
    }
  },

  computed: {
    ...mapState('Transaction', ['transactionType', 'transactionAmount', 'phone']),

    numbersFull() { return this.numbers.length == 10 },
    numbersEmpty() { return this.numbers.length == 0 },
  },

  methods: {
    ...mapActions('Transaction', ['setPhone']),
    ...mapActions('Auth', ['authPhone']),

    submit() {
      const phone = this.numbers.join('')

      this.setPhone(phone)

      this.loading = true
      this.error = undefined

      this.authPhone({
        phone,
        transactionType: this.transactionType,
        transactionAmount: this.transactionAmount,
      })

      .then(function() {
        this.$emit('phone-set')
      }.bind(this))

      .catch(function(error) {
        this.error = error.response.data?.underlyingError?.message ?? error.response?.data?.message ?? 'Unknown error'
      }.bind(this))

      .finally(() => this.loading = false)
    },

    backspace() {
      this.numbers.pop()
    },

    clear() {
      this.numbers = []
    },

    digit(number) {
      if (this.numbers.length >= 10) return

      this.numbers.push(number.toString())
    },

    keyListener(e) {

      //e.preventDefault()

      if (e.key == 'Backspace') {
        this.backspace()
      }
      else if (e.key == 'Escape') {
        this.clear()
      }
      else if (Number.isInteger(parseInt(e.key))) {
        this.digit(parseInt(e.key))
      }
      else if (e.key == 'Enter' && this.numbersFull) {
        this.submit()
      }
    },
  },

  watch: {

    isDisplayed: {
      handler() {
        this.isDisplayed
        ? document.addEventListener('keydown', this.keyListener)
        : document.removeEventListener('keydown', this.keyListener)
      },
      immediate: true
    },

    phone: {
      handler(phone) {
        if (! phone) {
          this.numbers = []
          this.error = undefined
          return
        }

        this.numbers = phone.split('')
      },
      immediate: true,
      deep: true,
    },
  },
}

</script>
<style scoped>
>>> .number-buttons .v-btn {
  margin-left: 8px !important;
  margin-right: 8px !important;
}
</style>
